import React from "react"
import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.es"
import Schema from "storyblok-js-client/source/schema"
import styled from "styled-components"

import { useUser } from "../../auth/hooks"
import LinkButton from "../../components/LinkButton"

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }
`

const TextContent = styled.div<{
  titleColor: string
  descriptionColor: string
}>`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h4 {
    color: ${({ titleColor }) => titleColor};
    font-family: ${({ theme }) => theme.fonts.title};
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
  }

  // The following class is referred to from Storyblok,
  // DO NOT REMOVE.
  .discount-code {
    font-family: ${({ theme }) => theme.fonts.title};
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin: 10px 0;
  }

  .description {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: ${({ descriptionColor }) => descriptionColor};
    margin: 0;

    p {
      margin: 0;
    }
  }

  .button-wrapper {
    max-width: 120px;
    bottom: 0;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.sm}) and (max-width: ${({ theme }) =>
      theme.breakpoints.md}) {
    flex: 1;
    padding-left: 0px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex: 1;
    padding-left: 0px;
  }
`

interface Props {
  promoDetails: Promo_cards.Card
}

const DiscountPrompt: React.FC<Props> = ({ promoDetails }) => {
  const resolver = new RichTextResolver(Schema)
  const { user } = useUser()

  const renderCardContent = () => (
    <Container>
      <TextContent
        titleColor={promoDetails.titleColor}
        descriptionColor={promoDetails.descriptionColor}
      >
        <h4>{promoDetails.title}</h4>
        {user?.profile?.profileType === "ASSOCIATE" ? (
          <p className="description">
            Contact your Principal reseller about applying this promotion.
          </p>
        ) : (
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: resolver.render(promoDetails.description),
            }}
          />
        )}
        <div className="button-wrapper">
          <LinkButton
            to={!promoDetails.wholeCardIsLink ? promoDetails.buttonLink : ""}
            target="_blank"
            padding="10px 10px 10px 0px"
            color={promoDetails.buttonColor}
          >
            {promoDetails.buttonText}
          </LinkButton>
        </div>
      </TextContent>
    </Container>
  )

  if (promoDetails.wholeCardIsLink) {
    return (
      <a href={promoDetails.buttonLink} target="_blank" rel="noreferrer">
        {renderCardContent()}
      </a>
    )
  }

  return renderCardContent()
}

export default DiscountPrompt
