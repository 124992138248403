import React, { useState } from "react"
import styled from "styled-components"

import { useGetSiteUnderMaintenance } from "../../auth/hooks"
import { Card, Loader, CardCarousel } from "../../components"
import { UnderMaintenanceContext } from "../../components/Nav/UnderMaintenanceContext"
import { formatMonthToString } from "../../utils/date"
import MonthEarnings from "./MonthEarnings"

const TabContainer = styled.div`
  display: flex;
  gap: 30px;
  margin: 0px 30px;

  .tab {
    position: relative;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.tiara};
    color: ${({ theme }) => theme.palette.black};
    padding: 10px 20px;
    font-size: 12px;
    border-radius: 15px 15px 0px 0px;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;

    &::before {
      content: "";
      position: absolute;
      left: -10px;
      bottom: 0;
      height: 10px;
      width: 10px;
      background-color: ${({ theme }) => theme.palette.tiara};
      clip-path: path(
        "M0,
        10 C6,
        10 10,
        6 10,
        0 L10,
        10 Z"
      );
      transition: background-color 0.2s;
    }

    &::after {
      content: "";
      transform: scaleX(-1);
      position: absolute;
      right: -10px;
      bottom: 0;
      height: 10px;
      width: 10px;
      background-color: ${({ theme }) => theme.palette.tiara};
      clip-path: path(
        "M0,
        10 C6,
        10 10,
        6 10,
        0 L10,
        10 Z"
      );
      transition: background-color 0.2s;
    }

    &:hover {
      background-color: ${({ theme }) => theme.palette.mineShaft};
      color: ${({ theme }) => theme.palette.white};

      &::before,
      &::after {
        background-color: ${({ theme }) => theme.palette.mineShaft};
      }
    }

    &.active {
      background-color: ${({ theme }) => theme.palette.white};
      color: ${({ theme }) => theme.palette.black};
      pointer-events: none;
      z-index: 1;

      &::before,
      &::after {
        background-color: ${({ theme }) => theme.palette.white};
      }
    }
  }
`

const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const UnderMaintenanceCard = styled.div`
  cursor: pointer;

  h4 {
    font-family: ${({ theme }) => theme.fonts.title};
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
  }

  p {
    max-width: 80%;
    font-size: 12px;
    line-height: 20px;
    margin: 0;
  }
`

const PerformanceSummaryError = styled.div`
  h4 {
    font-weight: 500;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
`

interface Props {
  performanceSummaries: {
    label: string
    data: Reseller.PerformanceSummaryNode[]
    isBusiness: boolean
  }[]
  loading: boolean
}

const SummaryCardChanger: React.FC<Props> = ({
  performanceSummaries,
  loading,
}) => {
  const { siteUnderMaintenance } = useGetSiteUnderMaintenance()

  const [activeSummary, setActiveSummary] = useState(0)
  return siteUnderMaintenance ? (
    <Card padding="20px" backgroundColor="white" gridArea="earnings">
      <UnderMaintenanceContext.Consumer>
        {({ openUnderMaintenanceModal }) => (
          <UnderMaintenanceCard onClick={openUnderMaintenanceModal}>
            <h4>Under Maintenance</h4>
            <p>
              Your summary information is not available while the reseller
              portal in under maintenance. Check back soon to see how
              you&apos;re doing this month.
            </p>
          </UnderMaintenanceCard>
        )}
      </UnderMaintenanceContext.Consumer>
    </Card>
  ) : (
    <Card padding="0px" backgroundColor="transparent" gridArea="earnings">
      {performanceSummaries.length > 1 && (
        <TabContainer>
          {performanceSummaries.map((summary, index) => (
            <div
              key={`${summary.label}-${index}`}
              className={`tab ${activeSummary === index ? "active" : ""}`}
              onClick={() => setActiveSummary(index)}
            >
              {summary.label}
            </div>
          ))}
        </TabContainer>
      )}

      {loading ? (
        <Card padding="20px">
          <LoadingContainer>
            <Loader />
          </LoadingContainer>
        </Card>
      ) : !performanceSummaries[activeSummary].data ? (
        <Card padding="20px" key="monthly-earnings">
          <PerformanceSummaryError>
            <h4>Performance Summary</h4>
            <p>{`There was a problem loading your ${
              performanceSummaries[activeSummary].isBusiness ? "business'" : ""
            } performance summary data. Check back again soon.`}</p>
          </PerformanceSummaryError>
        </Card>
      ) : performanceSummaries[activeSummary].data?.length === 1 ? (
        <Card padding="20px" key="monthly-earnings">
          <MonthEarnings
            performanceSummary={performanceSummaries[activeSummary].data?.[0]}
            isBusiness={performanceSummaries[activeSummary].isBusiness}
          />
        </Card>
      ) : (
        <CardCarousel
          swiperKey="earningsSummary"
          cards={performanceSummaries[activeSummary]?.data?.map(
            (summary: Reseller.PerformanceSummaryNode, index: number) => ({
              label: `View ${formatMonthToString(
                new Date(
                  performanceSummaries[activeSummary].data?.[
                    performanceSummaries[activeSummary].data?.length -
                      (index + 1)
                  ].startDate
                )
              )}`,
              children: (
                <Card padding="20px" key={`monthly-earnings-${index}`}>
                  <MonthEarnings
                    performanceSummary={{ ...summary }}
                    isBusiness={performanceSummaries[activeSummary].isBusiness}
                  />
                </Card>
              ),
            })
          )}
        />
      )}
    </Card>
  )
}

export default SummaryCardChanger
