import { Link } from "gatsby"
import React, { useMemo } from "react"
import styled from "styled-components"

import { useUser } from "../../auth/hooks"
import {
  Card,
  PageHeader,
  PageGuard,
  InviteAssociateCard,
  PageNotificationBanner,
} from "../../components"
import { UniqueLinkCodeContext } from "../../components/Nav/ShareCodeContext"
import Seo from "../../components/seo"
import DiscountPrompt from "../../dashboard/components/DiscountPrompt"
import OrderMachine from "../../dashboard/components/OrderMachine"
import Promo from "../../dashboard/components/Promo"
import SummaryCardChanger from "../../dashboard/components/SummaryCardChanger"
import {
  useGetBusinessPerformanceSummary,
  useGetPerformanceSummary,
  useGetPromoCards,
} from "../../dashboard/hooks"
import { convertDateTimeZone, timeUntilDate } from "../../utils/date"

const CardGrid = styled.div<{
  numSummariesShowing: number
  userIsPrincipal: boolean
}>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    "earnings"
    "promo"
    "devicePromo"
    "order"
    ${({ userIsPrincipal }) =>
      userIsPrincipal ? '"inviteAssociate"' : undefined};
  column-gap: 20px;
  row-gap: 15px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: repeat(2, auto);
    grid-template-areas:
      "earnings earnings"
      "promo promo"
      "devicePromo order"
      ${({ userIsPrincipal }) =>
        userIsPrincipal ? '"inviteAssociate inviteAssociate"' : undefined};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "earnings earnings earnings order"
      "promo promo devicePromo order"
      ${({ userIsPrincipal }) =>
        userIsPrincipal
          ? '"inviteAssociate inviteAssociate inviteAssociate inviteAssociate"'
          : undefined};
    row-gap: 20px;
  }
`

const PromoCards = styled.div`
  grid-area: promo;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
    gap: 20px;
  }
`

const DashboardPage = (): React.ReactElement => {
  const { user } = useUser()
  const userIsPrincipal = user?.profile?.profileType === "PRINCIPAL"

  const { data: performanceData, loading: performanceLoading } =
    useGetPerformanceSummary()

  const { data: businessPerformanceData, loading: businessPerformanceLoading } =
    useGetBusinessPerformanceSummary(!userIsPrincipal)

  const performanceSummaries = useMemo(() => {
    const result = [
      {
        label: "Personal earnings",
        data: performanceData?.performanceSummary,
        isBusiness: false,
      },
    ]

    if (businessPerformanceData) {
      result.push({
        label: "Business earnings",
        data: businessPerformanceData?.businessPerformanceSummary,
        isBusiness: true,
      })
    }

    return result
  }, [performanceData?.performanceSummary, businessPerformanceData])

  const profileFullyActivated = user?.profile?.isProfileActivated
  const profileCompleted = user?.profile?.contractSigned
  const trainingCompleted = user?.profile?.trainingCompleted
  const verificationStatus = user?.profile?.verificationStatus
  const hasShippingAddress = !!user?.address?.find(
    (address) => address.type === "SHIPPING"
  )
  const hasBillingAddress = !!user?.address?.find(
    (address) => address.type === "INVOICING"
  )
  const hasBothAddresses = hasShippingAddress && hasBillingAddress

  const promoCards = useGetPromoCards()

  const shouldShowBanner = useMemo(() => {
    let result = false
    if (user) {
      const daysPast = timeUntilDate(convertDateTimeZone(user.dateJoined))
        .split(" ")[0]
        .substring(1)
      if (parseInt(daysPast) <= 5) {
        result = true
      }
    }
    return result
  }, [user])

  return (
    <PageGuard>
      <Seo title="Dashboard" />
      <PageHeader
        cypressTestId="dashboard-page-header"
        title="Welcome to the Yoco Reseller Portal"
      />
      <PageNotificationBanner notificationState={shouldShowBanner}>
        {profileFullyActivated && !hasBothAddresses ? (
          <span>
            Please re-enter your shipping address and billing address on your{" "}
            <Link className="highlighted" to="profile/">
              profile
            </Link>
            .
          </span>
        ) : profileFullyActivated && hasBothAddresses ? (
          <span>
            Woohoo! You can now access your full dashboard. Start exploring!
            <br />
            <UniqueLinkCodeContext.Consumer>
              {({ toggleReferralCodeModal }) => (
                <span
                  className="highlighted open-modal"
                  onClick={toggleReferralCodeModal}
                >
                  {" "}
                  Send Unique Link{" "}
                </span>
              )}
            </UniqueLinkCodeContext.Consumer>
            here or from the navigation to start referring merchants.
          </span>
        ) : !profileCompleted ? (
          <span>
            <Link className="highlighted" to="profile/">
              Complete your profile{" "}
            </Link>
            to start the vetting process and become a certified reseller.
          </span>
        ) : !trainingCompleted ? (
          <span>
            Almost there!{" "}
            <Link className="highlighted" to="training/">
              Complete your training
            </Link>{" "}
            to become an official Yoco Reseller
          </span>
        ) : verificationStatus === "PENDING" ? (
          "We are currently verifying your identification documents. Once we have verified your identity, you will have full access to your dashboard."
        ) : verificationStatus === "FAILED" ? (
          "We encountered an issue while verifying your identification documents. Please contact Yoco support for help resolving this issue."
        ) : user?.profile?.profileType === "PRINCIPAL" ? (
          <span>
            Almost there! <br />
            Please contact Yoco support for help in completing your Principal
            profile
          </span>
        ) : null}
      </PageNotificationBanner>
      <CardGrid
        numSummariesShowing={
          profileFullyActivated
            ? performanceData?.performanceSummary?.length
            : 0
        }
        userIsPrincipal={userIsPrincipal}
      >
        {profileFullyActivated && (
          <SummaryCardChanger
            performanceSummaries={performanceSummaries}
            loading={performanceLoading || businessPerformanceLoading}
          />
        )}

        <PromoCards>
          {promoCards.map((card) => (
            <Card
              key={card._uid}
              backgroundColor={card.backgroundColor}
              padding={"25px"}
            >
              <DiscountPrompt promoDetails={card} />
            </Card>
          ))}
        </PromoCards>
        <Card gridArea="devicePromo" backgroundColor="#014737" padding="0px">
          <Promo />
        </Card>
        <Card gridArea="order" padding="10px">
          <OrderMachine />
        </Card>
        {user?.profile?.profileType === "PRINCIPAL" &&
        user?.profile?.isProfileActivated ? (
          <InviteAssociateCard gridArea="inviteAssociate" externalHeading />
        ) : null}
      </CardGrid>
    </PageGuard>
  )
}

export default DashboardPage
