import React, { useRef, useState } from "react"
import styled from "styled-components"

import Chip from "../../components/Chip"
import ToolTip from "../../components/ToolTip"
import { convertDateTimeZone, timeUntilDate } from "../../utils/date"

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .card-header {
    font-weight: 500;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 10px 20px;
    gap: 20px;
  }
`

const TotalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-right: 0px;
  gap: 10px;

  h4 {
    font-size: 12px;
    line-height: 22px;
    font-weight: 400;
    margin-bottom: 0px;
  }
  h5 {
    font-size: 11px;
    line-height: 22px;
    font-weight: 400;
    margin-bottom: 0px;
  }

  .total-earning-amount {
    font-size: 39px;
    line-height: 50px;
    font-weight: 600;
    margin-bottom: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: 0px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 10px;

    h4 {
      font-size: 14px;
      margin-bottom: 10px;
    }
    h5 {
      font-size: 13px;
      margin-bottom: 10px;
    }

    .total-earning-amount {
      font-size: 50px;
    }
  }
`

const TooltipText = styled.p`
  max-width: 150px;
  margin: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    .section-amount {
      font-size: 24px;
      max-width: 300px;
    }
  }
`

const DetailedInfoContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-wrap: nowrap;
  }
`

const DetailedInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;

  .icon-section {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .count {
      color: ${({ theme }) => theme.palette.blue};
      font-size: 16px;
      line-height: 17px;
      margin-bottom: 5px;
      margin-right: 5px;
    }

    .percentage {
      font-size: 11px;
      line-height: 17px;
      margin-bottom: 5px;
    }
  }

  .section-amount {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    margin: 0;
  }

  .section-title {
    font-size: 11px;
    line-height: 17px;
    font-weight: 400;
    margin: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    .section-amount {
      font-size: 24px;
    }
  }
`

interface Props {
  performanceSummary: Reseller.PerformanceSummaryNode
  isBusiness?: boolean
}

const MonthEarnings: React.FC<Props> = ({
  performanceSummary,
  isBusiness = false,
}) => {
  const tpvTooltip = useRef<HTMLDivElement>(null)
  const [showTooltip, setShowTooltip] = useState(false)
  const now = new Date()
  const performanceStartDate = new Date(performanceSummary.startDate)

  const formatTimeUntilPayments = () => {
    let dateTimeUntil = timeUntilDate(
      convertDateTimeZone(performanceSummary.invoiceDate)
    )
    const isNegative = dateTimeUntil.indexOf("-")

    if (isNegative > -1) {
      dateTimeUntil = dateTimeUntil.replace("-", "")
      return `Invoiced ${dateTimeUntil} ago`
    }

    return `Invoice in ${dateTimeUntil}`
  }

  return (
    <Container>
      <TotalContainer>
        <div>
          {isBusiness ? (
            <h4 className="card-header">
              {`Business Earnings
                    ${
                      now.getMonth() === performanceStartDate.getMonth()
                        ? "this month"
                        : "last month"
                    }`}
            </h4>
          ) : (
            <h4 className="card-header">
              {`Earnings
                      ${
                        now.getMonth() === performanceStartDate.getMonth()
                          ? "this month"
                          : "last month"
                      }`}
            </h4>
          )}

          <p className="total-earning-amount">
            R
            {Math.round(
              (performanceSummary.fomMerchantsEarnings +
                performanceSummary.activeMerchantsEarnings +
                performanceSummary.totalProcessingValue.earnings +
                Number.EPSILON) *
                100
            ) / 100}
          </p>
        </div>
        <div>
          <Chip size="large" text={formatTimeUntilPayments()} />
        </div>
      </TotalContainer>
      <DetailedInfoContainer>
        <DetailedInfo>
          <div className="icon-section">
            <p className="count">{performanceSummary.fomMerchants}</p>
            <img
              src="/images/dashboard/icon-connected-merchants.svg"
              alt="Merchants icon"
            />
          </div>
          <p className="section-amount">
            R{performanceSummary.fomMerchantsEarnings}
          </p>
          <p className="section-title">
            Fully Onboarded <br /> Merchants
          </p>
        </DetailedInfo>
        <DetailedInfo>
          <div className="icon-section">
            <p className="count">{performanceSummary.activeMerchants}</p>
            <img
              src="/images/dashboard/icon-active-merchants.svg"
              alt="Active icon"
            />
          </div>
          <p className="section-amount">
            R{performanceSummary.activeMerchantsEarnings}
          </p>
          <p className="section-title">
            Fully Activated <br /> Merchants
          </p>
        </DetailedInfo>
        <div>
          {performanceSummary.potentialTpv?.earnings &&
          performanceSummary.potentialTpv?.requiredMerchants > 0 ? (
            <ToolTip
              open={showTooltip}
              referenceElement={tpvTooltip}
              displayLeftSide
            >
              <TooltipText>
                You could be earning from R
                {performanceSummary.potentialTpv.earnings} TPV if you activate{" "}
                {performanceSummary.potentialTpv.requiredMerchants} more
                merchants
              </TooltipText>
            </ToolTip>
          ) : null}

          <DetailedInfo
            ref={tpvTooltip}
            onMouseEnter={() => {
              setShowTooltip(performanceSummary.potentialTpv ? true : false)
            }}
            onMouseLeave={() => {
              setShowTooltip(false)
            }}
          >
            <div className="icon-section">
              {!isBusiness && (
                <div>
                  <p className="count percentage">
                    {performanceSummary.totalProcessingValue.percentage}%
                  </p>
                </div>
              )}
              <img src="/images/dashboard/icon-tpv.svg" alt="Merchants icon" />
            </div>
            <p className="section-amount">
              R{performanceSummary.totalProcessingValue.earnings}
            </p>
            <p className="section-title">
              TPV of <br /> {performanceSummary.totalProcessingValue.total}
            </p>
          </DetailedInfo>
        </div>
      </DetailedInfoContainer>
    </Container>
  )
}

export default MonthEarnings
