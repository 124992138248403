import { gql } from "@apollo/client"

export const GET_PERFORMANCE_SUMMARY = gql`
  query getPerformanceSummary {
    performanceSummary {
      startDate
      invoiceDate
      fomMerchants
      fomMerchantsEarnings
      activeMerchants
      activeMerchantsEarnings
      totalProcessingValue {
        percentage
        earnings
        total
      }
      potentialTpv {
        percentage
        earnings
        total
        requiredMerchants
      }
    }
  }
`

export const GET_BUSINESS_PERFORMANCE_SUMMARY = gql`
  query getBusinessPerformanceSummary {
    businessPerformanceSummary {
      startDate
      invoiceDate
      fomMerchants
      fomMerchantsEarnings
      activeMerchants
      activeMerchantsEarnings
      totalProcessingValue {
        percentage
        earnings
        total
      }
      potentialTpv {
        percentage
        earnings
        total
        requiredMerchants
      }
    }
  }
`
