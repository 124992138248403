import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import LinkButton from "../../components/LinkButton"

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.white};
  padding: 25px 25px 0px 25px;
  gap: 10px;

  h4 {
    font-family: ${({ theme }) => theme.fonts.title};
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
    max-width: 180px;
  }

  p {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 0px;
  }

  .button-wrapper {
    max-width: 200px;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.md}) and (max-width: ${({ theme }) =>
      theme.breakpoints.lg}) {
    grid-template-areas: "image image" "title title" "description description" "link link";
  }
`

const ImageContainer = styled.div`
  grid-area: image;
  max-height: 85px;
  max-width: 120px;
  overflow: hidden;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-height: 130px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    max-height: 85px;
  }
`
const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const Promo: React.FC = () => {
  const promoLink =
    "https://a.storyblok.com/f/127702/x/6836a39bb0/yoco-pdf-user-guide-khumo.pdf"

  return (
    <Container>
      <TextContainer>
        <h4>The Yoco Khumo</h4>
        <p>
          Get up to speed with our fastest device yet. The most affordable smart
          card machine in SA.
        </p>
      </TextContainer>
      <Footer>
        <ImageContainer>
          <StaticImage
            src="../../images/dashboard/yoco-khumo.png"
            alt="Yoco Khumo machine"
            aspectRatio={8 / 15}
            style={{ objectFit: "contain", height: "100%" }}
            imgStyle={{
              objectFit: "contain",
              height: "100%",
            }}
          />
        </ImageContainer>
        <div className="button-wrapper">
          <LinkButton
            onClick={() => null}
            to={promoLink}
            color="#FF9E00"
            padding="0px"
          >
            Download digital flyer
          </LinkButton>
        </div>
      </Footer>
    </Container>
  )
}

export default Promo
