import { useQuery } from "@apollo/client"
import { graphql, useStaticQuery } from "gatsby"
import { useMemo } from "react"

import {
  GET_PERFORMANCE_SUMMARY,
  GET_BUSINESS_PERFORMANCE_SUMMARY,
} from "./queries"

interface PerformanceSummaryData {
  performanceSummary: Reseller.PerformanceSummaryNode[]
}

interface BusinessPerformanceSummaryData {
  businessPerformanceSummary: Reseller.PerformanceSummaryNode[]
}

export const useGetPerformanceSummary = (): {
  data: PerformanceSummaryData
  loading: boolean
} => {
  const { data, loading } = useQuery(GET_PERFORMANCE_SUMMARY, {
    context: { clientName: "private-api" },
  })
  return { data: data, loading: loading }
}

export const useGetBusinessPerformanceSummary = (
  skip = true
): {
  data: BusinessPerformanceSummaryData
  loading: boolean
} => {
  const { data, loading } = useQuery(GET_BUSINESS_PERFORMANCE_SUMMARY, {
    context: { clientName: "private-api" },
    skip: skip,
  })
  return { data: data, loading: loading }
}

export const useGetPromoCards = (): Promo_cards.Card[] => {
  const promoCardData: Storyblok.StoryBlokData = useStaticQuery(graphql`
    query promoCards {
      allStoryblokEntry(
        filter: { full_slug: { glob: "dashboard-promo-cards/*" } }
      ) {
        edges {
          node {
            uuid
            name
            slug
            content
          }
        }
      }
    }
  `)

  const promoCards: Promo_cards.Card[] = useMemo(
    () =>
      promoCardData.allStoryblokEntry.edges.map((card) =>
        JSON.parse(card.node.content)
      ),
    [promoCardData.allStoryblokEntry.edges]
  )

  return promoCards
}
