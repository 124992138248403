import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import Arrow from "../../components/Arrow"

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  justify-content: space-between;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0px;
  }
`

const TextContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.blueDianne};

  h4 {
    font-family: ${({ theme }) => theme.fonts.title};
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 12px;
  }

  p {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 5px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 20px 15px;
  }
`

const MachineButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const MachineButton = styled.a<{
  backgroundColor?: string
}>`
  height: 75px;
  display: flex;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ? backgroundColor : theme.palette.white};
  border-radius: 10px;
  margin-top: 10px;
  overflow: hidden;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: 90px;
  }

  &:hover {
    cursor: pointer;
    .arrow-icon {
      transform: translateX(5px);
    }
  }

  .imageContainer {
    flex: 2;
    padding: 10px 30px 0px 10px;
    margin-left: 0px;
    height: 100%;

    @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.sm}) and (max-width: ${({ theme }) =>
        theme.breakpoints.md}) {
      margin-bottom: -10px;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding: 20px 15px 0px 10px;
    }
  }

  .textContainer {
    flex: 5;
    color: ${({ theme }) => theme.palette.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 15px 5px 0px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h5 {
        font-family: ${({ theme }) => theme.fonts.title};
        font-size: 16px;
        line-height: 30px;
        font-weight: 500;
        margin: 0;
      }
    }

    .arrow-icon {
      transition: 0.2s;
    }
  }
`

const OrderMachine: React.FC = () => {
  return (
    <Container>
      <TextContent>
        <h4>Order a Yoco Card Machine</h4>
        <p>
          Keep stock on hand, or order as you sell with our quick click shop.
        </p>
      </TextContent>
      <MachineButtonContainer>
        <MachineButton
          backgroundColor="#014737"
          href={process.env.GATSBY_KHUMO_URL}
          target="_blank"
          referrerPolicy="no-referrer"
        >
          <div className="imageContainer">
            <StaticImage
              src="../../images/dashboard/khumo-angled.png"
              alt="Yoco Khumo"
            />
          </div>
          <div className="textContainer">
            <div className="title">
              <h5>Yoco Khumo</h5>
              <div className="arrow-icon">
                <Arrow color="#FF9E00" />
              </div>
            </div>
          </div>
        </MachineButton>
        <MachineButton
          backgroundColor="#FA5D37"
          href={process.env.GATSBY_GO_URL}
          target="_blank"
          referrerPolicy="no-referrer"
        >
          <div className="imageContainer">
            <StaticImage
              src="../../images/dashboard/go-angled.png"
              alt="Yoco Go"
            />
          </div>
          <div className="textContainer">
            <div className="title">
              <h5>Yoco Go</h5>
              <div className="arrow-icon">
                <Arrow />
              </div>
            </div>
          </div>
        </MachineButton>
      </MachineButtonContainer>
    </Container>
  )
}

export default OrderMachine
